import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import { toast, ToastContainer } from 'react-toastify';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
} from 'reactstrap';
import { downloadExamSchedules } from 'shared/utils/ApiCommands';
import CustomDropZoneLayout from "../../../components/documents/FileUploaderDropZone";
import {NZDocumentType} from "../../../../lib/common/models/nzDocument";

const NavigationExamSchedule: React.FC = () => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };


    const handleUpload = (message: string) => {
        toast.success(message);
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h4 className="card-title">Exam Schedule</h4>
                            </CardHeader>
                            <CardBody>
                                <Button color="primary"  onClick={() => {
                                    downloadExamSchedules(loggedUser.token);
                                }}>
                                    Download Current Exam Schedule
                                </Button>
                                <CustomDropZoneLayout
                                    onFinish={(fileMeta: any) => handleUpload(fileMeta)}
                                    type={NZDocumentType.EXAM_SCHEDULE}
                                    id={"exam_schedule.pdf"}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ToastContainer />
            </div>
        </>
    );
};

export default NavigationExamSchedule;
