import React from 'react';
import NavigationExamCreate from '../../views/pages/admin/navigation_exam/navigationExamCreate';
import NavigationExamList from '../../views/pages/admin/navigation_exam/navigationExamList';
import CancelledExamList from '../../views/pages/admin/cancelled_exam/cancelledExamList';
import NavigationExamDetail from '../../views/pages/admin/navigation_exam/navigationExamDetail';
import AddCredits from '../../views/pages/admin/payment/addCredits';
import PaymentList from '../../views/pages/admin/transactions/paymentList';
import TransactionList from '../../views/pages/admin/transactions/transactionList';
import CandidateList from '../../views/pages/admin/candidate/candidateList';
import CalendarExamList from '../../views/pages/admin/calendar/ExamList';
import ExamAnalytics from '../../views/pages/admin/exam_analytics/ExamAnalytics';
import CandidateDetail from '../../views/pages/admin/candidate/candidateDetail';
import CompletedExamList from '../../views/pages/admin/completed_exam/completedExamList';
import NavigationExamResults from '../../views/pages/admin/completed_exam/navigationExamResults';
import ProfileResetPassword from '../../views/pages/common/profile/ProfileResetPassword';
import ClosedExamList from '../../views/pages/admin/closed_exam/closedExamList';
import ClosedExamDetail from '../../views/pages/admin/closed_exam/closedExamDetail';
import ClosedExamResults from '../../views/pages/admin/closed_exam/closedExamResults';
import ExamResourcesList from '../../views/pages/admin/exam_resources/examResourceList';
import ExamCentreDashboard from '../../views/pages/questionusers/ExamCentreDashboard';

interface Route {
  path?: string;
  name: string;
  icon?: string;
  component?: React.ComponentType;
  mini?: string;
  state?: string;
  views?: Route[];
  collapse?: boolean;
}

export const routes: Route[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    component: ExamCentreDashboard,
  },
  {
    path: '/candidate/list',
    name: 'Candidates',
    icon: 'nc-icon nc-single-02',
    component: CandidateList,
  },
  {
    collapse: true,
    name: 'Exams',
    icon: 'nc-icon nc-book-bookmark',
    state: 'questionCollapse',
    views: [
      {
        path: '/navigation_exam/list',
        name: 'Exams',
        mini: 'EX',
        component: NavigationExamList,
      },
      {
        path: '/completed_exam/list',
        name: 'Results declared',
        mini: 'ER',
        component: CompletedExamList,
      },
      {
        path: '/cancelled_exam/list',
        name: 'Cancelled exams',
        mini: 'EC',
        component: CancelledExamList,
      },
      {
        path: '/closed_exam/list',
        name: 'Closed Exams',
        mini: 'CE',
        component: ClosedExamList,
      },
      {
        path: '/exam_resource/list',
        name: 'Exam Resources',
        mini: 'ER',
        component: ExamResourcesList,
      },
    ],
  },
  {
    path: '/exams/calendar',
    name: 'Exam Calendar',
    icon: 'nc-icon nc-calendar-60',
    component: CalendarExamList,
  },
  {
    collapse: true,
    name: 'Payments',
    icon: 'nc-icon nc-money-coins',
    state: 'paymentsCollapse',
    views: [
      {
        path: '/payments/history',
        name: 'Payments History',
        mini: 'PH',
        component: PaymentList,
      },
      {
        path: '/transactions/history',
        name: 'Transactions History',
        mini: 'TH',
        component: TransactionList,
      },
    ],
  },
];

export const hiddenRoutes: Route[] = [
  {
    path: '/navigation_exam/results',
    name: 'Exam Results',
    mini: 'ER',
    component: NavigationExamResults,
  },
  {
    path: '/navigation_exam/detail',
    name: 'Exam Request Detail',
    mini: 'ED',
    component: NavigationExamDetail,
  },
  {
    path: '/closed_exam/detail',
    name: 'Closed Exam Detail',
    mini: 'ED',
    component: ClosedExamDetail,
  },
  {
    path: '/closed_exam/results',
    name: 'Closed Exam Results',
    mini: 'ER',
    component: ClosedExamResults,
  },
  {
    path: '/credits',
    name: 'Add Credit',
    mini: 'ED',
    component: AddCredits,
  },
  {
    path: '/navigation_exam/request',
    name: 'Request Exam',
    mini: 'EC',
    component: NavigationExamCreate,
  },
  {
    path: '/candidate/detail',
    name: 'Candidate Detail',
    mini: 'CD',
    component: CandidateDetail,
  },
  {
    path: '/recovery-password/confirm/:token?',
    name: 'Reset Password',
    mini: 'RP',
    component: ProfileResetPassword,
  },
]; 