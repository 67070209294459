import React from 'react';
import Dashboard from '../../views/pages/questionusers/mcaExaminerDashboard';
import QuestionUserList from '../../views/pages/admin/question/questionUserList';
import QuestionDetail from '../../views/pages/admin/question/questionDetail';
import QuestionBankList from '../../views/pages/admin/question/questionBankList';
import NavigationExamList from '../../views/pages/admin/navigation_exam/navigationExamList';
import ExamCenterList from '../../views/pages/admin/examCenter/examCenterList';
import NavigationExamDetail from '../../views/pages/admin/navigation_exam/navigationExamDetail';
import ExamCenterDetail from '../../views/pages/admin/examCenter/examCenterDetail';
import CancelledExamList from '../../views/pages/admin/cancelled_exam/cancelledExamList';
import ExamDocumentArchive from '../../views/pages/admin/examDocument/examDocumentArchive';
import NavigationExamPending from '../../views/pages/admin/navigation_exam/navigationExamPending';
import CandidateList from '../../views/pages/admin/candidate/candidateList';
import CandidateWithReportList from '../../views/pages/admin/candidate/candidateWithReportList';
import ProfileResetPassword from '../../views/pages/common/profile/ProfileResetPassword';
import CandidateDetail from '../../views/pages/admin/candidate/candidateDetail';
import ExamMark from "../../views/pages/ExamMark";
import ExamMarkViewOnly from "../../views/pages/ExamMarkViewOnly";
import ClosedExamList from "../../views/pages/admin/closed_exam/closedExamList";
import ClosedExamDetail from "../../views/pages/admin/closed_exam/closedExamDetail";
import ClosedExamResults from "../../views/pages/admin/closed_exam/closedExamResults";
import QuestionPipeline from '../../views/pages/admin/question/questionPipeline';

interface Route {
  path?: string;
  name: string;
  icon?: string;
  component?: React.ComponentType;
  mini?: string;
  state?: string;
  views?: Route[];
  collapse?: boolean;
}

export const routes: Route[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    component: Dashboard,
  },
  {
    collapse: true,
    name: 'Questions',
    icon: 'nc-icon nc-book-bookmark',
    state: 'questionCollapse',
    views: [
      {
        path: '/question/list',
        name: 'Question List',
        mini: 'QL',
        component: QuestionUserList,
      },
      {
        path: '/question/bank',
        name: 'Question Bank',
        mini: 'QB',
        component: QuestionBankList,
      },
      {
        path: '/question/pipeline',
        name: 'Question Pipeline',
        mini: 'QP',
        component: QuestionPipeline,
      },
    ],
  },
  {
    collapse: true,
    name: 'Exams',
    icon: 'nc-icon nc-book-bookmark',
    state: 'navigationCollapse',
    views: [
      {
        path: '/navigation_exam/list',
        name: 'Exams List',
        mini: 'EL',
        component: NavigationExamList,
      },
      {
        path: '/examDocument/archive/list',
        name: 'Exam Archive',
        mini: 'EA',
        component: ExamDocumentArchive,
      },
      {
        path: '/closed_exam/list',
        name: 'Closed Exams',
        mini: 'CE',
        component: ClosedExamList,
      },
      {
        path: '/navigation_exam/cancelled/list',
        name: 'Cancelled exams',
        mini: 'EC',
        component: CancelledExamList,
      },
    ],
  },
  {
    collapse: true,
    name: 'Arbitration Marks',
    icon: 'nc-icon nc-book-bookmark',
    state: 'markCollapse',
    views: [
      {
        path: '/navigation_exam/markers',
        name: 'Pending to Mark',
        mini: 'EM',
        component: NavigationExamPending,
      },
    ],
  },
  {
    collapse: true,
    name: 'Candidates',
    icon: 'nc-icon nc-single-02',
    state: 'candidatesCollapse',
    views: [
      {
        path: '/candidate/list',
        name: 'Candidates list',
        mini: 'CL',
        component: CandidateList,
      },
      {
        path: '/candidateWithReports/list',
        name: 'Candidate reports',
        mini: 'CR',
        component: CandidateWithReportList,
      },
    ],
  },
  {
    path: '/examCenter/list',
    name: 'Exam Centers',
    icon: 'nc-icon nc-shop',
    component: ExamCenterList,
  },
];

export const hiddenRoutes: Route[] = [
  {
    path: '/question/detail',
    name: 'Question Detail',
    mini: 'CD',
    component: QuestionDetail,
  },
  {
    path: '/navigation_exam/detail',
    name: 'Exam Request Detail',
    mini: 'ED',
    component: NavigationExamDetail,
  },
  {
    path: '/examCenter/detail',
    name: 'Exam Center Detail',
    mini: 'ED',
    component: ExamCenterDetail,
  },
  {
    path: '/recovery-password/confirm/:token?',
    name: 'Reset Password',
    mini: 'RP',
    component: ProfileResetPassword,
  },
  {
    path: '/candidate/detail',
    name: 'Candidate Detail',
    mini: 'CD',
    component: CandidateDetail,
  },
  {
    path: '/navigation_exam/marks',
    name: 'Current',
    mini: 'CM',
    component: ExamMark,
  },
  {
    path: '/navigation_exam/marksmca',
    name: 'Current',
    mini: 'CM',
    component: ExamMarkViewOnly,
  },
  {
    path: '/closed_exam/detail',
    name: 'Closed Exam Detail',
    mini: 'ED',
    component: ClosedExamDetail,
  },
  {
    path: '/closed_exam/results',
    name: 'Closed Exam Results',
    mini: 'ER',
    component: ClosedExamResults,
  },
]; 