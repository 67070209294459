import React from 'react';
import ExamMark from '../../views/pages/ExamMark';
import NavigationExamPending from '../../views/pages/admin/navigation_exam/navigationExamPending';
import ProfileResetPassword from '../../views/pages/common/profile/ProfileResetPassword';
import MarkerDashboard from '../../views/pages/questionusers/markerDashboard';

interface Route {
  path?: string;
  name: string;
  icon?: string;
  component?: React.ComponentType;
  mini?: string;
  state?: string;
  views?: Route[];
  collapse?: boolean;
}

export const routes: Route[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    component: MarkerDashboard,
  },
  {
    collapse: true,
    name: 'Pending Marks',
    icon: 'nc-icon nc-book-bookmark',
    state: 'navigationCollapse',
    views: [
      {
        path: '/navigation_exam/markers',
        name: 'Mark Exams',
        mini: 'EM',
        component: NavigationExamPending,
      }
    ],
  },
];

export const hiddenRoutes: Route[] = [
  {
    path: '/recovery-password/confirm/:token?',
    name: 'Reset Password',
    mini: 'RP',
    component: ProfileResetPassword,
  },
  {
    path: '/navigation_exam/marks',
    name: 'Current',
    mini: 'CM',
    component: ExamMark,
  },
]; 