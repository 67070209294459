import ApiCaller from '../lib/ApiCaller';
import NZDocument from '../lib/common/models/nzDocument';
import NZDocumentCollection from '../lib/common/models/nzDocumentCollection';
import Settings from '../lib/settings';

class DocumentsApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getAllImages(): Promise<void | any[]> {
    const url = '/v1/documents/images';
    return this.extracted(url);
  }

  async getByHash(hash: string): Promise<NZDocument> {
    const url = '/v1/documents/hash/' + hash;
    return await this.apiCaller.call(url, 'GET').then((doc: any) => {
      return new NZDocument(doc);
    });
  }

  async getByType(type: string): Promise<NZDocument> {
    const url = '/v1/documents/type/' + type;
    return await this.apiCaller.call(url, 'GET').then((doc: any) => {
      return new NZDocument(doc);
    });
  }

  async updateDiagnosisReportStatus(
    status: string,
    reportId: string,
    token?: string,
    description?: string,
  ): Promise<NZDocument> {
    const url = '/v1/documents/diagnosisReports/' + reportId;
    return this.apiCaller
      .call(
        url,
        'POST',
        {
          status: status,
          description: description,
        },
        token,
      )
      .then((data) => {
        return new NZDocument(data.files);
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  async updateDocumentDescription(
    documentId: string,
    description: string,
    token?: string,
  ): Promise<NZDocument> {
    const url = `/v1/documents/document/${documentId}/description`;
    return this.apiCaller
      .call(
        url,
        'POST',
        {
          description,
        },
        token,
      )
      .then((data) => {
        return new NZDocument(data);
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  async addResourcefromGalleryToQuestion(
    imageId: string,
    questionName: string,
  ): Promise<NZDocument> {
    const url = `/v1/question/${questionName}/addResourceToQuestionFromGallery/${imageId}`;
    return await this.apiCaller
      .call(url, 'POST')
      .then((doc: any) => {
        return new NZDocument(doc);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getAll(filter?: any): Promise<NZDocumentCollection> {
    const queryParams = new URLSearchParams(filter);
    const queryParamsString = queryParams.toString()
      ? `?${queryParams.toString()}`
      : '';

    return this.apiCaller
      .call(`/v1/documents/document${queryParamsString}`, 'GET')
      .then((data) => {
        const dArr = data.files.map((doc: any) => new NZDocument(doc));
        return new NZDocumentCollection(dArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  deleteMarkerReportByExamId(
    examId: string,
    token?: string,
  ): Promise<void | any | undefined> {
    return this.apiCaller.call(
      `/v1/documents/document/markerReport/${examId}`,
      'DELETE',
      undefined,
      token,
    );
  }

  getAllByProjectId(projectId: string): Promise<void | NZDocumentCollection> {
    const url = '/v1/documents/document/' + projectId;
    return this.apiCaller.call(url, 'GET').then((data) => {
      const dArr = data.map((doc: any) => new NZDocument(doc));
      return new NZDocumentCollection(dArr);
    });
  }

  downloadDocumentByHash(hash: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/documents/document/download/' + hash, 'GET')
      .then((file) => {
        return file;
      });
  }

  downloadMarkerReportByExamId(id: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/documents/document/markerReport/' + id, 'GET')
      .then((file) => {
        return file;
      });
  }

  async downloadExamSchedules(): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/documents/examSchedules', 'GET')
      .then((file) => {
        return file;
      });
  }


  async changeExamSchedules(): Promise<void | any | undefined> {
    return this.apiCaller
        .download('/v1/documents/examSchedules', 'POST')
        .then((file) => {
          return file;
        });
  }

  private extracted(url: string) {
    return this.apiCaller.call(url, 'GET').then((data) => {
      return data.files.map((doc: any) => {
        return {
          original: `${Settings.getApiURL()}/v1/documents/images/${
            doc.fileHash
          }`,
          thumbnail: `${Settings.getApiURL()}/v1/documents/images/${
            doc.fileHash
          }`,
        };
      });
    });
  }
}

export default DocumentsApi;
