import React, { useState, useCallback } from 'react';

import nzDocument from '../../../../lib/common/models/nzDocument';
import Settings from '../../../../lib/settings';
import closeModelImageBlue from '../../../../images/x-circle-blue.svg';

import CustomDropZoneLayout from '../FileUploaderDropZone';
import { DocumentDownloadContainer } from './compStyle';
import {
  ModalCloseImage,
  ModalContainer,
  ModalContent400,
} from './uploader-modal-styles';
import {useSelector} from "react-redux";
import {AppState} from "../../../../store/store";

interface ContainerProps {
  label: string;
  examId?: string;
  informDateTime?: boolean;
  doc?: Array<nzDocument>;
  isModalOpened: boolean;
  setIsModalOpened: (isModalOpened: boolean) => void;
  onChangeDocs?: () => void;
}

const ExamScriptUploader: React.FC<ContainerProps> = ({
  label,
  examId,
  informDateTime,
  doc = [],
  isModalOpened,
  setIsModalOpened,
  onChangeDocs = () => {},
}) => {
  const [currentDoc, setCurrentDoc] = useState<nzDocument>(doc[0]);
  const [modalDownloadIsOpen, setModalDownloadIsOpen] = useState(false);
  const [pickupDate, setPickupDate] = useState<Date>();
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const updateFile = useCallback(
    (data: any) => {
      setCurrentDoc(new nzDocument(data));
      setIsModalOpened(false);
      onChangeDocs();
    },
    [setCurrentDoc, setIsModalOpened],
  );

  const parseLabel = (label: string) => {
    if (label.includes('__')) {
      label = label.split('__')[0];
    }
    return label.replace(/\_/gi, ' ');
  };

  const closeModal = useCallback(() => {
    setModalDownloadIsOpen(false);
  }, []);

  return (
    <>
      {currentDoc && (
        <DocumentDownloadContainer
          onClick={() => setModalDownloadIsOpen(!modalDownloadIsOpen)}>
          <p>{currentDoc.displayName}</p>
        </DocumentDownloadContainer>
      )}

      {isModalOpened && (
        <ModalContainer open={isModalOpened}>
          <ModalContent400>
            <span onClick={() => setIsModalOpened(!isModalOpened)}>
              <ModalCloseImage src={closeModelImageBlue} />
            </span>
            <h1>Upload Script</h1>
            <hr />
            <p>{parseLabel(label)}</p>
            <CustomDropZoneLayout
              onFinish={(fileMeta: any) => updateFile(fileMeta)}
              expiredDate={pickupDate}
              type={label}
              projectId={examId!}
            />
          </ModalContent400>
        </ModalContainer>
      )}

      {modalDownloadIsOpen && currentDoc && (
        <ModalContainer open={modalDownloadIsOpen}>
          <ModalContent400>
            <span onClick={closeModal}>
              <ModalCloseImage src={closeModelImageBlue} />
            </span>
            <h1>Script download</h1>
            <hr />
            <p>
              <span>File Name :</span> {currentDoc.displayName}
            </p>
            <p>
              <span>File Type :</span> {currentDoc.mimeType}
            </p>
            <p>
              <span>File Hash :</span> {currentDoc.fileHash}{' '}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/*<a*/}
              {/*  href={`/verifyhash/${currentDoc.fileHash}`}*/}
              {/*  target={'_blank'}*/}
              {/*  rel="noreferrer">*/}
              {/*  <img src={blockchain} />*/}
              {/*</a>*/}
            </p>
            {informDateTime && (
              <p>
                <span>Expires At:</span> {currentDoc.expirationDate}
              </p>
            )}
            {!informDateTime && (
              <p>
                <span>Created At:</span> {currentDoc.createdAt}
              </p>
            )}
            <hr />
            <div className={'button'}>
              <button
                onClick={() =>
                  window.open(
                    `${Settings.getApiURL()}/v1/documents/document/download/${
                      currentDoc.fileHash
                    }?token=${loggedUser.token}`,
                  )
                }>
                DOWNLOAD FILE
              </button>
            </div>
          </ModalContent400>
        </ModalContainer>
      )}
    </>
  );
};

export default ExamScriptUploader;
